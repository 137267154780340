import React, { useContext, useEffect, useState } from "react";
import SingleItem from "../SingleItem";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import { Link, useNavigate } from "react-router-dom";

const UserPermissionDepositReject = () => {
  const navigate = useNavigate();
    const { authUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
      fetch(`https://api.rubiconlibertygroup.xyz/api/admin/permission/user/deposit/reject/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setLoading(false);
        });
    }, []);
  
   
    if (!(authUser?.user)) {
      if (authUser?.user === undefined) {
          return (
              <>
                  <div className="loader-css">
                      <RotatingSquare
                          visible={true}
                          height="100"
                          width="100"
                          color="#4fa94d"
                          ariaLabel="rotating-square-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                      />
                  </div>
              </>
          );
      } else {
          return (
              <>
                  <h1>unauthorized</h1>
              </>
          );
      }
  
  
  
  } else {
  
  
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 class="page-title">Reject Deposits</h6>
            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
          <Link
              to="/admin/user/deposit/view"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="fa-solid fa-list-ul me-1"></i>
             All Deposits
            </Link>
          <Link
              to="/admin/user/deposit/pending/view"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="fa-solid fa-list-ul me-1"></i>
             Pending Deposits
            </Link>
            
            <Link
              to="/admin/user/deposit/success/view"
              className="btn btn-sm btn-outline-primary addBtn"
            >
              <i className="fa-solid fa-list-ul me-1"></i>Successful Deposits
            </Link>
            <button onClick={() => navigate(-1)}  className="btn btn-sm btn-outline-primary"> <i className="la la-undo"></i> Back</button>
          </div>
          </div>
          <div class="row justify-content-center">
           
            <div class="col-md-12">
              <div class="card b-radius--10">
                <div class="card-body p-0">
                  <div class="table-responsive--sm table-responsive">
                    <table class="table table--light style--two mb-0">
                      <thead>
                        <tr>
                          <th>Network Type | Transaction</th>
                          <th>Initiated</th>
                          <th>Full Name</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length !== 0 ? (
                          data.map((data, index) => {
                            if (data) {
                              return (
                                <SingleItem
                                  data={data}
                                  index={index}
                                  key={data._id}
                                ></SingleItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td className="text-muted text-center" colspan="100%">
                              Data not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
  };
export default UserPermissionDepositReject;