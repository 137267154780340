import React from "react";
import { Link } from "react-router-dom";
import TimeAgo from "timeago-react";
const SingleItem = ({ data, setNotificationShow }) => {
  return (
    <>
      <div className="dropdown-menu__body ">
        <Link
          to={`/admin/users/details/${data?.user_id}`}
          className="dropdown-menu__item"
          onClick={() => setNotificationShow(false)}
        >
          <div className="navbar-notifi d-block">
            <div className="row">
              <div className="col-9">
                <div className="navbar-notifi__right">
                  <h6 className="notifi__title">{data?.user_name} </h6>
                  <span className="time">{data?.message}</span>
                </div>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <p>
                  <TimeAgo datetime={data?.createdAt} />
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SingleItem;
