import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { RotatingSquare } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
const WithdrawalsDetails = () => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState([]);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/single/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, [updateData]);

  const [userData, setuserData] = useState([]);
  useEffect(() => {
    if (data?.user_id) {
      fetch(
        `https://api.rubiconlibertygroup.xyz/api/admin/user/view/single/${data?.user_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setuserData(data.data);
        });
    }
  }, [data]);

  const handleAccept = (id) => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/accept/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setupdateData(data);
        toast.success(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => console.log(error));
  };
  const handleReject = (id) => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/reject/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setupdateData(data);
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => console.log(error));
  };

  // Freeeze user

  const refSubmitDis = useRef(false);
  const [FreeezeModalshow, setFreeezeModalShow] = useState(false);
  const handleFreeezeModalClose = () => setFreeezeModalShow(false);
  const handleFreeezeModalShow = () => setFreeezeModalShow(true);
  const [errorMessage, setErrorMessage] = useState([]);
  // view data

  // store data

  const handleFreeezeSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current = true;
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .put(
        `https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/freeeze/${id}`,
        InpuData,
        config
      )
      .then((data) => {
        handleFreeezeModalClose();
        toast.error(`${data?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setupdateData(data?.data);
        event.target.reset();
        refSubmitDis.current = false;
      })
      .catch((error) => {
        refSubmitDis.current = false;
        if (error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors);
        } else {
          setErrorMessage(error?.response?.data);
        }
      });
  };

  if (authUser?.EditororViewer === "viewer") {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">
            {userData?.fname} {userData?.lname} Withdraw Requested
            {data?.AmountWithVat} USD
          </h6>
          <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-sm btn-outline-primary"
            >
              <i className="la la-undo"></i> Back
            </button>
          </div>
        </div>

        <div className="row mb-none-30">
          <div className="col-lg-4 col-md-4 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">
                  Withdraw Via {data?.NetworkType}
                </h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Trx Number
                    <span className="fw-bold"> {data?.Transaction}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Username
                    <span className="fw-bold">
                      <Link to={`/admin/users/details/${userData?._id}`}>
                        {userData?.fname} {userData?.lname}
                      </Link>
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount
                    <span className="fw-bold">{data?.AmountWithVat} USD</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Charge
                    <span className="fw-bold">
                      {parseFloat(data?.AmountWithVat) -
                        parseFloat(data?.Amount)}
                      USD
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    After Charge
                    <span className="fw-bold">{data?.Amount} USD</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Payable
                    <span className="fw-bold">
                      {data.Status === 1 ? <>{data?.Amount} USD</> : <>0 USD</>}
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Status
                    {data.Status === 0 ? (
                      <span className="badge badge--warning text-warning">
                        Pending
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 1 ? (
                      <span class="badge badge--success text-success">
                        Approved
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 2 ? (
                      <span class="badge badge--danger text-danger">
                        Rejected
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 3 ? (
                      <span class="badge badge--danger text-danger">
                        Freeezed
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="card-title border-bottom pb-2">
                  User Withdraw Information
                </h5>

                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Network Type</h6>
                    <p>{data?.NetworkType}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Withdrawal address</h6>
                    <p>{data?.WithdrawalAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">
            {userData?.fname} {userData?.lname} Withdraw Requested
            {data?.AmountWithVat} USD
          </h6>
          <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-sm btn-outline-primary"
            >
              <i className="la la-undo"></i> Back
            </button>
          </div>
        </div>

        <div className="row mb-none-30">
          <div className="col-lg-4 col-md-4 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">
                  Withdraw Via {data?.NetworkType}
                </h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Trx Number
                    <span className="fw-bold"> {data?.Transaction}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Username
                    <span className="fw-bold">
                      <Link to={`/admin/users/details/${userData?._id}`}>
                        {userData?.fname} {userData?.lname}
                      </Link>
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount
                    <span className="fw-bold">{data?.AmountWithVat} USD</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Charge
                    <span className="fw-bold">
                      {parseFloat(data?.AmountWithVat) -
                        parseFloat(data?.Amount)}
                      USD
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    After Charge
                    <span className="fw-bold">{data?.Amount} USD</span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Payable
                    <span className="fw-bold">
                      {data.Status === 1 ? <>{data?.Amount} USD</> : <>0 USD</>}
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Status
                    {data.Status === 0 ? (
                      <span className="badge badge--warning text-warning">
                        Pending
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 1 ? (
                      <span class="badge badge--success text-success">
                        Approved
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 2 ? (
                      <span class="badge badge--danger text-danger">
                        Rejected
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 3 ? (
                      <span class="badge badge--danger text-danger">
                        Freeezed
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="card-title border-bottom pb-2">
                  User Withdraw Information
                </h5>

                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Network Type</h6>
                    <p>{data?.NetworkType}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Withdrawal address</h6>
                    <p>{data?.WithdrawalAddress}</p>
                  </div>
                </div>

                {data?.remark && (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <h6>Freeeze Reason</h6>
                      <p>{data?.remark}</p>
                    </div>
                  </div>
                )}

                <div className="row mt-4">
                  <div className="col-md-12">
                    {data.Status === 0 || data.Status === 3 ? (
                      <>
                        <button
                          onClick={() => handleAccept(data?._id)}
                          className="btn btn-outline-success ms-1 approveBtn"
                          data-id="108"
                          data-amount="990,000.00 USD"
                        >
                          <i className="fas la-check"></i> Approve
                        </button>
                        <button
                          onClick={() => handleReject(data?._id)}
                          className="btn btn-outline-danger ms-1 rejectBtn"
                          data-id="108"
                        >
                          <i className="fas fa-ban"></i> Reject
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                    {data.Status === 0 && (
                      <button
                        onClick={handleFreeezeModalShow}
                        className="btn btn-outline-danger ms-1 rejectBtn"
                        data-id="108"
                      >
                        <i className="fas fa-ban"></i> Freeeze
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={FreeezeModalshow} onHide={handleFreeezeModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 class="modal-title">
                <span class="type">Freeeze </span>
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFreeezeSubmitData}>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="ban_reason" className="required">
                    Remark
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Remark"
                    name="remark"
                    rows="4"
                    id="ban_reason"
                  ></textarea>
                  {errorMessage?.remark && (
                    <span className="text-danger pt-2">
                      {errorMessage?.remark?.msg}
                    </span>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  disable={refSubmitDis}
                  type="submit"
                  className="btn btn-primary h-45 w-100"
                >
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default WithdrawalsDetails;
