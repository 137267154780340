import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import SingleItem from "./SingleItem";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "./../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import PriceUpdateModal from "./PriceUpdateModal";
const Stocks = () => {
    const [show, setShow] = useState(false);
    const { authUser } = useContext(AuthContext);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // view data

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [paginateData, setPaginateData] = useState([]);
    const [updateData, setupdateData] = useState([]);

    const [results, setResults] = useState({});
    const [dataVulue, setDataVulue] = useState({});
    const refSubmitDis = useRef();

    useEffect(() => {
        fetch(`https://api.rubiconlibertygroup.xyz/api/admin/stocks/view`, {
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                setData(data.data);
                setPaginateData(data);
                setLoading(false);
            });
    }, [updateData, results]);

    const handlePage = (event) => {
        try {
            const pageNumber = event?.selected + 1;
            const config = {
                headers: {
                    "content-type": "application/json",
                },
            };
            axios
                .get(
                    `https://api.rubiconlibertygroup.xyz/api/admin/stocks/view?page=${pageNumber}`,
                    config
                )
                .then((response) => {
                    setData(response?.data?.data);
                    setPaginateData(response?.data);
                })
                .catch((error) => { });
        } catch (error) {
            console.log(error);
        }
    };

    const handleEnableDisabled = (id) => {
        fetch(
            `https://api.rubiconlibertygroup.xyz/api/admin/stocks/enable/disable/${id}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                setupdateData(data);
                if (data?.data?.status === 0) {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                if (data?.data?.status === 1) {
                    toast.error(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => console.log(error));
    };

    // store data

    const [image, setImage] = useState("");

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmitData = (event) => {
        event.preventDefault();
        refSubmitDis.current.setAttribute("disabled", true);
        const userData = { ...dataVulue, image: image };
        if (userData.image === "") {
            toast.success(`please choose your image`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            axios
                .post(
                    `https://api.rubiconlibertygroup.xyz/api/admin/stocks/store`,
                    userData,
                    config
                )
                .then((response) => {
                    event.target.reset();
                    setShow(false);
                    toast.success(`${response?.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setResults(response);
                    refSubmitDis.current.removeAttribute("disabled");
                })
                .catch((error) => {
                    toast.error(`${error?.response?.data?.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    refSubmitDis.current.removeAttribute("disabled");
                });
        }
    };

    const handleInputBlur = (event) => {
        setResults({});
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...dataVulue };
        newData[field] = value;
        setDataVulue(newData);
    };


      // price modal 

  const [singleDataId, setSingleDataId] = useState(null);

  const [priceModalshow, setPriceModalshow] = useState(false);

  const handlePriceModalShow = (id) => {
      setSingleDataId(id);
      setPriceModalshow(true);
  };


    if (!(authUser?.crypto_currency)) {
        if (authUser?.crypto_currency === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }

    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">Stocks List</h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">

                            <button
                                onClick={handleShow}
                                className="btn btn-sm btn-outline-primary addBtn"
                            >
                                <i className="las la-plus"></i>Add New
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card b-radius--10 ">
                                <div className="card-body p-0">
                                    <div className="table-responsive--md  table-responsive">
                                        <table className="table table--light style--two mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Symbol</th>
                                                    <th>Price</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length !== 0 ? (
                                                    data.map((data, index) => {
                                                        if (data) {
                                                            return (
                                                                <SingleItem
                                                                    data={data}
                                                                    index={index}
                                                                    key={data._id}
                                                                    handleEnableDisabled={handleEnableDisabled}
                                                                    paginateData={paginateData}
                                                                    handlePriceModalShow={handlePriceModalShow}
                                                                ></SingleItem>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td className="text-muted text-center" colspan="100%">
                                                            Data not found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* paginateLinks */}
                                <CustomPagination
                                    data={paginateData}
                                    handlePage={handlePage}
                                ></CustomPagination>
                                {/* paginateLinks */}
                            </div>
                        </div>
                    </div>

                    {/* Modal  */}

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Stocks</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmitData}>
                                <div class="modal-body">
                                    <div class="form-group">
                                        <label htmlFor="name" class="required">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            onBlur={handleInputBlur}
                                           required
                                            id="name"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="price" class="required">
                                        Price
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            name="price"
                                            onBlur={handleInputBlur}
                                           required
                                            id="price"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label htmlFor="symbol" class="required">
                                        Symbol
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="symbol"
                                            onBlur={handleInputBlur}
                                           required
                                            id="symbol"
                                        />
                                    </div>
                                  
                                    <div class="form-group">
                                        <label htmlFor="image" class="required">
                                            Image
                                        </label>
                                        <input
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            class="form-control"
                                            name="image"
                                            onChange={handleImage}
                                           required
                                            id="image"
                                        />
                                        <small class="mt-2  ">
                                            Supported files: <b>jpeg, jpg, png.</b> Image will be resized
                                            into 400x400 px{" "}
                                        </small>
                                    </div>
                                    <button
                                        type="submit"
                                        ref={refSubmitDis}
                                        class="btn btn-primary w-100 h-45"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>

                    {(() => {
                    if (priceModalshow) {
                        return (
                            <PriceUpdateModal
                                singleDataId={singleDataId}
                                setShow={setPriceModalshow}
                                show={priceModalshow}
                                setupdateData={setupdateData}
                            ></PriceUpdateModal>
                        );
                    }
                })()}
                
                </>
            );

        }
    }




};

export default Stocks;