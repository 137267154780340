import React, { useEffect, useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Contexts/AuthContext/AuthProvider";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [userIP, setUserIP] = useState('')
  const getIP = async () => {
    const res = await fetch("https://api.ipify.org");
    const data = await res.text();
    setUserIP(data);
  };

  useEffect(() => {
    getIP();
  }, []);

  const { LoginWithEmail } = useContext(AuthContext);
  const refAdminLoginSubmitDis = useRef();
  const refAdminLoginShowRef = useRef();
  const refTwoFactorVerifyShowRef = useRef();

  const [AdminData, setAdminData] = useState({});
  const [user, setUserValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...user };
    newUser[field] = value;
    setUserValue(newUser);
  };

  const handleAdminLogin = (event) => {
    event.preventDefault();
    refAdminLoginSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    fetch("https://api.rubiconlibertygroup.xyz/api/admin/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InpuData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          toast.error(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refAdminLoginSubmitDis.current.removeAttribute("disabled");
        } else {
          toast.success(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refAdminLoginShowRef.current.style.display = "none";
          refTwoFactorVerifyShowRef.current.style.display = "block";
          setAdminData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/admin/dashboard";

  const [TwoFactorVerifyInputData, setTwoFactorVerifyInputData] = useState({});

  const handleTwoFactorVerifyInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...TwoFactorVerifyInputData };
    newUser[field] = value;
    setTwoFactorVerifyInputData(newUser);
  };

  const refTwoFactorSubmitDis = useRef();

  const handleTwoFactorVerify = (event) => {
    event.preventDefault();
    refTwoFactorSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const VerifySendData = {
      ...InpuData,
      _id: AdminData?.data?._id,
      email: AdminData?.data?.email,
      userIP
    };
    fetch("https://api.rubiconlibertygroup.xyz/api/admin/two/factor/verify", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Beare ${AdminData?.token}`,
      },
      body: JSON.stringify(VerifySendData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          toast.error(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refTwoFactorSubmitDis.current.removeAttribute("disabled");
        } else {
          const user = data;
          localStorage.setItem("pcx_admin_ID", JSON.stringify(user));
          const expires = new Date(Date.now() + 30 * 6000 * 1000).toUTCString();
          document.cookie = `pcx_admin_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires};`;
          event.target.reset();
          toast.success(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if (user.data) {
            LoginWithEmail(user);
            navigate(userFrom, { replace: true });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const refResendSubmitDis = useRef();

  const handleResendCode = (event) => {
    event.preventDefault();
    refResendSubmitDis.current.setAttribute("disabled", true);
    refTwoFactorSubmitDis.current.setAttribute("disabled", true);
    fetch("https://api.rubiconlibertygroup.xyz/api/admin/two/factor/code/resend", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email: AdminData.data.email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          toast.error(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          refResendSubmitDis.current.removeAttribute("disabled");
          refTwoFactorSubmitDis.current.removeAttribute("disabled");
          setAdminData(data);
          toast.success(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div
        className="login-main"
        // style={{
        //   backgroundImage:
        //     "url('https://gffexvip.biz/assets/admin/images/login.jpg')",
        // }}
      >
        <div className="container custom-container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8 col-sm-11">
              <div className="login-area">
                <div className="login-wrapper">
                  <div className="login-wrapper__top">
                    <h3 className="title text-white">
                      Welcome to <strong>Rubicon</strong>
                    </h3>
                    <p className="text-white">Admin Login to Rubicon Dashboard</p>
                  </div>
                  <div className="login-wrapper__body">
                    <form
                      onSubmit={handleAdminLogin}
                      className="cmn-form mt-30 verify-gcaptcha login-form"
                      ref={refAdminLoginShowRef}
                    >
                      <div className="form-group">
                        <label htmlFor="username" className="required">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          onBlur={handleInputBlur}
                          required
                          id="username"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="required">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onBlur={handleInputBlur}
                          required
                          id="password"
                        />
                      </div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            name="remember"
                            type="checkbox"
                            id="remember"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="remember"
                          >
                            Remember Me
                          </label>
                        </div>
                        <Link
                          to="/admin/password/reset"
                          className="forget-text"
                        >
                          Forgot Password?
                        </Link>
                      </div>
                      <button
                        ref={refAdminLoginSubmitDis}
                        type="submit"
                        className="login-btn cmn-btn w-100"
                      >
                        LOGIN
                      </button>
                    </form>
                    <div
                      style={{ display: "none" }}
                      ref={refTwoFactorVerifyShowRef}
                    >
                      <form
                        onSubmit={handleTwoFactorVerify}
                        className="cmn-form mt-30 verify-gcaptcha login-form"
                      >
                        <div className="form-group">
                          <label htmlFor="code" className="required">
                            Two Factor Code
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="code"
                            onBlur={handleTwoFactorVerifyInputBlur}
                            required
                            id="code"
                            min="0"
                          />
                        </div>

                        <button
                          ref={refTwoFactorSubmitDis}
                          type="submit"
                          className="login-btn cmn-btn w-100"
                        >
                          Verify
                        </button>
                      </form>
                      <form onSubmit={handleResendCode}>
                        <button
                          ref={refResendSubmitDis}
                          type="submit"
                          className="login-btn cmn-btn w-100"
                        >
                          Resend Code
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
