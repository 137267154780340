import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
const DepositsDetails = () => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState([]);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/deposit/single/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, [updateData]);

  const [userData, setuserData] = useState([]);
  useEffect(() => {
    if (data?.user_id) {
      fetch(
        `https://api.rubiconlibertygroup.xyz/api/admin/user/view/single/${data?.user_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setuserData(data.data);
        });
    }
  }, [data]);

  const handleAccept = (id) => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/deposit/accept/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setupdateData(data);
        toast.success(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => console.log(error));
  };
  const handleReject = (id) => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/deposit/reject/${id}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setupdateData(data);
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => console.log(error));
  };

  if (authUser?.EditororViewer === "viewer") {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">
            {userData?.fname} {userData?.lname} requested {data?.AmountWithVat}
            USD
          </h6>

          <button
            onClick={() => navigate(-1)}
            className="btn btn-sm btn-outline-primary"
          >
            <i className="la la-undo"></i> Back
          </button>
        </div>

        <div className="row mb-none-30 justify-content-center">
          <div className="col-xl-4 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">
                  Deposit Via {data?.NetworkType}
                </h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Transaction Number
                    <span className="fw-bold"> {data?.Transaction}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Username
                    <span className="fw-bold">
                      <Link href={`/admin/users/details/${userData?._id}`}>
                        {userData?.fname} {userData?.lname}
                      </Link>
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Network Type
                    <span className="fw-bold"> {data?.NetworkType}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount
                    <span className="fw-bold">{data?.AmountWithVat} USD</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Charge
                    <span className="fw-bold">
                      {parseFloat(data?.AmountWithVat) -
                        parseFloat(data?.Amount)}
                      USD
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    After Charge
                    <span className="fw-bold">{data?.Amount} USD</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Payable
                    <span className="fw-bold">
                      {data.Status === 1 ? <>{data?.Amount} </> : <>0 </>}
                      USD
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Status
                    {data.Status === 0 ? (
                      <span className="badge badge--warning text-warning">
                        Pending
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 1 ? (
                      <span className="badge badge--success text-success">
                        Approved
                      </span>
                    ) : (
                      ""
                    )}
                    {data.Status === 2 ? (
                      <span className="badge badge--danger text-danger">
                        Rejected
                      </span>
                    ) : (
                      ""
                    )}
                    <span>
                      <br />
                      {/* 1 week ago */}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="card-title mb-50 border-bottom pb-2">
                  User Deposit Information
                </h5>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Network Type</h6>
                    <p>{data?.NetworkType}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Transaction screenshot</h6>
                    <a
                      href={`https://api.rubiconlibertygroup.xyz/${data?.screenshot}`}
                      target="_blank"
                      className="me-3"
                      alt=""
                    >
                      <i className="fa fa-file"></i> Attachment
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">
            {userData?.fname} {userData?.lname} requested {data?.amountWithVat}
            USD
          </h6>

          <button
            onClick={() => navigate(-1)}
            className="btn btn-sm btn-outline-primary"
          >
            <i className="la la-undo"></i> Back
          </button>
        </div>

        <div className="row mb-none-30 justify-content-center">
          <div className="col-xl-4 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="mb-20 text-muted">
                  Deposit Via {data?.NetworkType}
                </h5>
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Date
                    <span className="fw-bold">
                      {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Transaction Number
                    <span className="fw-bold"> {data?.transaction}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Username
                    <span className="fw-bold">
                      <Link to={`/admin/users/details/${userData?._id}`}>
                        {userData?.fname} {userData?.lname}
                      </Link>
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Network Type
                    <span className="fw-bold"> {data?.NetworkType}</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Amount
                    <span className="fw-bold">{data?.amountWithVat} USD</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Charge
                    <span className="fw-bold">
                      {parseFloat(data?.amountWithVat) -
                        parseFloat(data?.amount)}
                      USD
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    After Charge
                    <span className="fw-bold">{data?.amount} USD</span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Payable
                    <span className="fw-bold">
                      {data.Status === 1 ? <>{data?.amount} </> : <>0 </>}
                      USD
                    </span>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    Status
                    {data.status === 0 ? (
                      <span className="badge badge--warning text-warning">
                        Pending
                      </span>
                    ) : (
                      ""
                    )}
                    {data.status === 1 ? (
                      <span className="badge badge--success text-success">
                        Approved
                      </span>
                    ) : (
                      ""
                    )}
                    {data.status === 2 ? (
                      <span className="badge badge--danger text-danger">
                        Rejected
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-6 mb-30">
            <div className="card b-radius--10 overflow-hidden box--shadow1">
              <div className="card-body">
                <h5 className="card-title mb-50 border-bottom pb-2">
                  User Deposit Information
                </h5>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Network Type</h6>
                    <p>{data?.NetworkType}</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <h6>Transaction screenshot</h6>
                    <a
                      href={`https://api.rubiconlibertygroup.xyz/${data?.screenshot}`}
                      target="_blank"
                      className="me-3"
                      alt=""
                    >
                      <i className="fa fa-file"></i> Attachment
                    </a>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12">
                    {data.status === 0 ? (
                      <>
                        <button
                          onClick={() => handleAccept(data?._id)}
                          className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleReject(data?._id)}
                          className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
                        >
                          Reject
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default DepositsDetails;
