import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "./../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import {  useNavigate } from "react-router-dom";

const AprovedLoan = () => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
    const [updateData, setupdateData] = useState([]);
    useEffect(() => {
      fetch(`https://api.rubiconlibertygroup.xyz/api/admin/loan/approved/view`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setPaginateData(data);
          setLoading(false);
        });
    }, [updateData]);
  
    const handleReject = (id) => {
      fetch(`https://api.rubiconlibertygroup.xyz/api/admin/loan/reject/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setupdateData(data);
          toast.error(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        })
        .catch((error) => console.log(error));
    };
    const handlePage = (event) => {
      try {
        const pageNumber = event?.selected + 1;
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .get(
            `https://api.rubiconlibertygroup.xyz/api/admin/loan/approved/view?page=${pageNumber}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    };
    if (!(authUser?.loan)) {
      if (authUser?.loan === undefined) {
          return (
              <>
                  <div className="loader-css">
                      <RotatingSquare
                          visible={true}
                          height="100"
                          width="100"
                          color="#4fa94d"
                          ariaLabel="rotating-square-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                      />
                  </div>
              </>
          );
      } else {
          return (
              <>
                  <h1>unauthorized</h1>
              </>
          );
      }



  } else {


  }
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div class="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 class="page-title">Approved Loan</h6>
            <button onClick={() => navigate(-1)}  className="btn btn-sm btn-outline-primary"> <i className="la la-undo"></i> Back</button>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <div class="card b-radius--10 ">
                <div class="card-body p-0">
                  <div class="table-responsive--md  table-responsive">
                    <table class="table table--light style--two">
                      <thead>
                        <tr>
                          <th>Full Name</th>
                          <th>Loan Amount</th>
                          <th>Loan Resaon</th>
                          <th>Create At</th>
  
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length !== 0 ? (
                          data.map((data, index) => {
                            if (data) {
                              return (
                                <SingleItem
                                  data={data}
                                  index={index}
                                  key={data._id}
                                  handleReject={handleReject}
                                  paginateData={paginateData}
                                ></SingleItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td className="text-muted text-center" colspan="100%">
                              Data not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* paginateLinks */}
                <CustomPagination data={paginateData} handlePage={handlePage}>
                  {" "}
                </CustomPagination>
                {/* paginateLinks */}
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  

export default AprovedLoan;