import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
const SingleItem = ({ data, index, paginateData }) => {
  const [userData, setuserData] = useState([]);
  useEffect(() => {
    if (data?.user_id) {
      fetch(
        `https://api.rubiconlibertygroup.xyz/api/admin/user/view/single/${data?.user_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setuserData(data.data);
        });
    }
  }, []);

  return (
    <>
      <tr>
        <td data-label="S.N.">
          {parseFloat(index + 1) +
            parseFloat(paginateData?.page) * parseFloat(paginateData?.limit) -
            10}
        </td>
        <td data-label="Full Name">
          <Link to={`/admin/users/details/${userData?._id}`}>
            <span className="d-block">
              {userData?.fname} {userData?.lname}
            </span>
          </Link>
        </td>
        <td data-label="Pairs">
          <span>{data.pairs}</span> <br />
        </td>
        <td data-label="Direction">
          {data.direction === "Buy" ? (
            <span className="badge badge--success text-success">Buy</span>
          ) : (
            <span className="badge badge--danger text-danger">Sell</span>
          )}
        </td>
        <td data-label="Profit" className={parseFloat(data?.profit) < 0 ? "text-danger" : "text-primary"}> {data?.profit}$</td>
        <td data-label="Lots"> {data?.lots}</td>
        <td data-label="Open Price"> {data?.lower_unit_price}</td>

        {data?.status && !data?.pending_order_status && (
          <td data-label="Current Price"> {data?.current_price}</td>
        )}
        {!data?.status && (
          <td data-label="Close Price"> {data?.current_price}</td>
        )}

        <td data-label="Date">
          {dateFormat(data?.createdAt, "d-m-yyyy h:MM:ss TT")}
        </td>
        <td data-label="Action">
          <Link
            to={`/admin/trade/log/details/${data._id}`}
            className="btn btn-sm btn-outline-primary ms-1"
          >
            <i className="la la-desktop"></i> Details
          </Link>
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
