import React from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
const SingleChatsItem = ({ data }) => {
  if (data?.recevier_email !== null) {
    return (
      <>
        {data?.message && (
          <li className="clearfix">
            <Link to={`/admin/staff/details/by/email/${data?.sender_email}`}>
              <div className="message-data text-end">
                <span className="message-data-time d-block mb-1">
                  {dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}
                </span>
                <div className="message other-message "> {data?.message} </div>
              </div>
            </Link>
          </li>
        )}
      </>
    );
  } else {
    if (!data?.attach && !data?.message) {
      return;
    }
    return (
      <>
        <li className="clearfix">
          <div className="message-data">
            <span className="message-data-time">
              {dateFormat(data?.createdAt, "dd mmm yyyy h:MM TT")}
            </span>
          </div>
          {data?.attach && (
            <img
              src={`https://api.rubiconlibertygroup.xyz/${data?.attach}`}
              alt=""
            />
          )}

          {data?.message && (
            <>
              <br />
              <br />
              <div className="message my-message">{data?.message}</div>
            </>
          )}
        </li>
      </>
    );
  }
};

export default SingleChatsItem;
