import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
const SocialSupport = () => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const [dataVulue, setDataVulue] = useState({});
  const refSubmitDis = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/social/support/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, [updateData]);

  const handleSubmitData = (event) => {
    event.preventDefault();
    setErrorMessage();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .post(
        `https://api.rubiconlibertygroup.xyz/api/admin/social/support/update`,
        InpuData,
        config
      )
      .then((response) => {
        event.target.reset();
        refSubmitDis.current.removeAttribute("disabled");
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setupdateData(data);
      })
      .catch((error) => {
        refSubmitDis.current.removeAttribute("disabled");
        if (error?.response?.data?.errors) {
          setErrorMessage(error?.response?.data?.errors);
        } else {
          setErrorMessage(error?.response?.data);
        }
      });
  };

  if (!authUser?.manage_section) {
    if (authUser?.manage_section === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Update Social Support</h6>
          <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
            <button
              onClick={() => navigate(-1)}
              className="btn btn-sm btn-outline-primary"
            >
              <i className="la la-undo"></i> Back
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <form onSubmit={handleSubmitData}>
                <div className="card-body">
                  <div className="payment-method-item">
                    <div className="payment-method-body">
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="dis" className="required">
                              Description
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="dis"
                                required=""
                                id="dis"
                                defaultValue={data?.dis}
                              />
                            </div>
                            {errorMessage?.dis && (
                              <span className="text-danger pt-2">
                                {errorMessage?.dis?.msg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="whatapp_link" className="required">
                              Whatsapp Link
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="whatapp_link"
                                required=""
                                id="whatapp_link"
                                defaultValue={data?.whatapp_link}
                              />
                            </div>
                            {errorMessage?.whatapp_link && (
                              <span className="text-danger pt-2">
                                {errorMessage?.whatapp_link?.msg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="tele_link" className="required">
                              Telegram Link
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                name="tele_link"
                                required=""
                                id="tele_link"
                                defaultValue={data?.tele_link}
                              />
                            </div>
                            {errorMessage?.tele_link && (
                              <span className="text-danger pt-2">
                                {errorMessage?.tele_link?.msg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="email" className="required">
                              Email Address
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                required=""
                                id="email"
                                defaultValue={data?.email}
                              />
                            </div>
                            {errorMessage?.email && (
                              <span className="text-danger pt-2">
                                {errorMessage?.email?.msg}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-footer">
                  <button
                    type="submit"
                    ref={refSubmitDis}
                    className="btn btn-primary w-100 h-45"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default SocialSupport;
