import React, { useEffect, useState } from "react";
import DragAndDrop from "./DragAndDrop";
import { RotatingSquare } from "react-loader-spinner";

const ManualGatewaysDragAndDrop = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(
      `https://api.rubiconlibertygroup.xyz/api/admin/gateway/manual/view/without/paginate`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  const [FilterData, setFilterData] = useState([]);

  const [FilterDataLoading, setFilterDataLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/gateway/manual/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFilterData(data.data);
        setFilterDataLoading(false);
      });
  }, []);

  const GatewaysData = data.filter((el) => {
    const filter = FilterData.map((x) => x.gateway_id).includes(el._id);
    if (!filter) {
      return true;
    }
  });

  let GatewaysFilterData = [];
  for (let item of FilterData) {
    for (let filterItem of data) {
      if (item.gateway_id === filterItem._id) {
        GatewaysFilterData.push(filterItem);
        break;
      }
    }
  }

  if (loading || FilterDataLoading) {
    return (
      <>
        <div className="loader-css">
          <RotatingSquare
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="rotating-square-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </>
    );
  }

  return (
    <>
      <DragAndDrop data={GatewaysData} FilterData={GatewaysFilterData} />
    </>
  );
};
export default ManualGatewaysDragAndDrop;
