import React, { useEffect, useState } from "react";
import DragAndDrop from "./DragAndDrop";
import { RotatingSquare } from "react-loader-spinner";

const LoanDragAndDrop = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/loan/package/view/without/paginate`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  const [FilterData, setFilterData] = useState([]);

  const [FilterDataLoading, setFilterDataLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/loan/package/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setFilterData(data.data);
        setFilterDataLoading(false);
        
      });
  }, []);

  const LoanData = data.filter((el) => {
    const filter = FilterData.map((x) => x.loan_id).includes(el._id);
    if (!filter) {
      return true;
    }
  });



let LoanFilterData = [];
for (let item of FilterData) {
    for (let filterItem of data) {
        if (item.loan_id === filterItem._id) {
            LoanFilterData.push(filterItem);
            break;
        }

    }
}


  if (loading || FilterDataLoading) {
    return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
  }

  return (
    <>
      <DragAndDrop data={LoanData} FilterData={LoanFilterData} />
    </>
  );
};
export default LoanDragAndDrop;
