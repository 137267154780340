import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { RotatingSquare } from "react-loader-spinner";
const AdminNotification = ({ setNotificationShow }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/notification/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <RotatingSquare
          visible={true}
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="rotating-square-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      {data.length !== 0 ? (
        data.map((data, index) => {
          if (data) {
            return (
              <SingleItem
                key={data?._id}
                data={data}
                index={index}
                setNotificationShow={setNotificationShow}
              ></SingleItem>
            );
          }
        })
      ) : (
        <tr>
          <td className="text-muted text-center" colspan="100%">
            Data not found
          </td>
        </tr>
      )}
    </>
  );
};

export default AdminNotification;
