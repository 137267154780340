import React from "react";
import { Link } from "react-router-dom";

const SingleItem = ({
  data,
  handleEnableDisabled,
  handlePriceModalShow,
  handleUpdatePricePlus,
  disablePriceBtn,
  handleUpdatePriceMinus,
}) => {
  return (
    <>
      <tr>
        <td data-label="Name">{data?.name}</td>
        <td data-label="Image">
          <div className="user">
            <div className="thumb">
              <img src={`https://api.rubiconlibertygroup.xyz/${data?.image}`} alt="" />
            </div>
            <span className="name"></span>
          </div>
        </td>
        <td data-label="Symbol">{data?.symbol}</td>
        <td data-label="Price">{data?.price} </td>
        <td>
          <button
            onClick={() => handleUpdatePriceMinus(data?._id)}
            className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
            disabled={disablePriceBtn === data?._id ? true : false}
          >
            <i className="las la-minus m-0"></i>
          </button>
          <button
            onClick={() => handlePriceModalShow(data?._id)}
            className="btn btn-sm btn-outline-primary editBtn ms-1"
          >
            <i className="la la-pencil "></i>Edit
          </button>

          <button
            onClick={() => handleUpdatePricePlus(data?._id)}
            className="btn btn-sm btn-outline-primary editBtn ms-1"
            disabled={disablePriceBtn === data?._id ? true : false}
          >
            <i className="las la-plus m-0"></i>
          </button>
        </td>
        <td data-label="Status">
          {data?.status === 1 ? (
            <span className="badge badge--success text-success">Enabled</span>
          ) : (
            <span>
              <span class="badge badge--warning text-warning">Disabled</span>
            </span>
          )}
        </td>
        <td data-label="Action">
          <Link
            to={`/admin/commodities/edit/${data._id}`}
            className="btn btn-sm btn-outline-primary editBtn"
          >
            <i className="la la-pencil"></i>Edit
          </Link>

          {data?.status === 1 ? (
            <button
              onClick={() => handleEnableDisabled(data._id)}
              className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
            >
              <i className="la la-eye-slash"></i>Disable
            </button>
          ) : (
            <button
              onClick={() => handleEnableDisabled(data._id)}
              className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
            >
              <i className="la la-eye"></i> Enable
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default SingleItem;
