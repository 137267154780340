import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useParams, useNavigate  } from 'react-router-dom';
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from '../../../../Contexts/AuthContext/AuthProvider';

const CategoryUpdate = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const { authUser } = useContext(AuthContext);
    const [TitleResults, setTitleResults] = useState({});
    const [TitleDataVulue, setTitleDataVulue] = useState({});
    const refTitleSubmitDis = useRef();

    const handleTitleSubmitData = event => {
        event.preventDefault();
        refTitleSubmitDis.current.setAttribute("disabled", true);
        fetch(`https://api.rubiconlibertygroup.xyz/api/admin/news/category/update/${id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(TitleDataVulue)
        })
            .then(res => res.json())
            .then(data => {
                if (data.success === false) {
                    toast.error(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.success(`${data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setTitleResults(data);
                    event.target.reset();
                }
                refTitleSubmitDis.current.removeAttribute("disabled");
            })
            .catch(error =>
                refTitleSubmitDis.current.removeAttribute("disabled")
            );
    }

    const handleInputBlur = event => {
        const value = event.target.value;
        const field = event.target.name;
        const newData = { ...TitleDataVulue };
        newData[field] = value;
        setTitleDataVulue(newData);
    }

    const [TitleData, setTitleData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://api.rubiconlibertygroup.xyz/api/admin/news/category/view/${id}`, {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) =>
            {
                setTitleData(data.data)
                setLoading(false);
            })
    }, [TitleResults])



    if (!(authUser?.manage_section)) {
        if (authUser?.manage_section === undefined) {
            return (
                <>
                    <div className="loader-css">
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>unauthorized</h1>
                </>
            );
        }



    } else {
        if (loading) {
            return (
                <>
                    <div className="loader-css" >
                        <RotatingSquare
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="rotating-square-loading"
                            wrapperStyle={{}
                            }
                            wrapperClass=""
                        />
                    </div>
                </>
            );
        } else {
    
            return (
                <>
                    <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
                        <h6 className="page-title">Update Category</h6>
                        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
                            <Link
                                    to="/admin/news/add"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>Add News
                                </Link>
                                <Link
                                    to="/admin/news/view"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>
                                  All News
                                </Link>
    
                              
                                <Link
                                    to="/admin/frontend/frontend-sections/gffex/about/us/section/three/view"
                                    className="btn btn-sm btn-outline-primary addBtn"
                                >
                                    <i className="fa-solid fa-list-ul me-1"></i>All Category
                                </Link>
                                <button onClick={() => navigate(-1)}  className="btn btn-sm btn-outline-primary"> <i className="la la-undo"></i> Back</button>
                            </div>
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-lg-12 col-md-12 mb-30">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleTitleSubmitData}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="sub_heading" className="required">Name</label>
                                                    <input type="text" onBlur={handleInputBlur} className="form-control" name="name" defaultValue={TitleData?.name} required="" id="sub_heading" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button ref={refTitleSubmitDis} type="submit" className="btn btn-primary w-100 h-45">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </>
            );
        }

    }
  

  

};

export default CategoryUpdate;