import React, {  useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
const TradeLogDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/trade/log/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
      });
  }, []);

  const [userData, setuserData] = useState([]);
  useEffect(() => {
    if (data?.user_id) {
      fetch(
        `https://api.rubiconlibertygroup.xyz/api/admin/user/view/single/${data?.user_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setuserData(data.data);
        });
    }
  }, [data]);

  return (
    <>
      <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
        <h6 className="page-title">
          {userData?.fname} {userData?.lname} Trade Details
        </h6>
        <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins">
        <button onClick={() => navigate(-1)}  className="btn btn-sm btn-outline-primary"> <i className="la la-undo"></i> Back</button>
        </div>
      </div>

      <div className="row mb-none-30 justify-content-center">
        <div className="col-xl-6 col-md-6 mb-30">
          <div className="card b-radius--10 overflow-hidden box--shadow1">
            <div className="card-body">
              <h5 className="mb-20 text-muted">Trade Via {data?.pairs}</h5>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Date
                  <span className="fw-bold">
                    {dateFormat(data.createdAt, "d-m-yyyy h:MM:ss TT")}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Transaction Number
                  <span className="fw-bold"> {data?.transaction}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Username
                  <span className="fw-bold">
                    <Link to={`/admin/users/details/${userData?._id}`}>
                      {userData?.fname} {userData?.lname}
                    </Link>
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Pairs
                  <span className="fw-bold"> {data?.pairs}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Direction
                  <span className="fw-bold">
                    {" "}
                    {data.direction === "Buy" ? (
                      <span className="badge badge--success text-success">
                        Buy
                      </span>
                    ) : (
                      <span className="badge badge--danger text-danger">
                        Sell
                      </span>
                    )}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Lots
                  <span className="fw-bold">{data?.lots} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Open Price
                  <span className="fw-bold">{data?.lower_unit_price} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {data?.status ? "Current Price" : "Close Price"}
                  <span className="fw-bold">{data?.current_price} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Take Profit
                  <span className="fw-bold">{data?.take_profit} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Set Loss
                  <span className="fw-bold">{data?.set_loss} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Handling Fee
                  <span className="fw-bold">0</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Margin
                  <span className="fw-bold">{data?.margin} </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Profit
                  <span
                    className={
                      data?.profit < 0
                        ? "fw-bold text-danger"
                        : "fw-bold text-primary"
                    }
                  >
                    {data?.profit}{" "}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Multiplier
                  <span className="fw-bold">{data?.multiplier}00 </span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-center">
                  Status
                  {data?.pending_order_status && (
                    <span className="badge badge--warning text-warning">
                      Pending
                    </span>
                  )}
                  {data.status && !data?.pending_order_status && (
                    <span className="badge badge--success text-success">
                      Running
                    </span>
                  )}
                  {!data.status && (
                    <span className="badge badge--danger text-danger">
                      Close
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeLogDetails;
