import React from "react";
import { Link } from "react-router-dom";
import TimeAgo from "timeago-react";
const SingleAllNotification = ({ data }) => {
  return (
    <>
      <tr>
        <td data-label="User Full Name">{data?.user_name}</td>
        <td data-label="Message">{data?.message}</td>
        <td data-label="Ago">
          <TimeAgo datetime={data?.createdAt} />
        </td>

        <td data-label="Action">
        <Link
              to={`/admin/users/details/${data.user_id}`}
              className="btn btn-sm btn-outline-primary"
            >
              <i className="las la-desktop"></i>User Details
            </Link>
        </td>
      </tr>
    </>
  );
};

export default SingleAllNotification;
