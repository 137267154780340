import React, { useContext, useEffect, useRef, useState } from "react";
import SingleItem from "../SingleItem";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import CustomPagination from "../../../CustomPagination/CustomPagination";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { DatePicker } from "antd";
import dateFormat from "dateformat";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const FreeezeWithdrawals = () => {
  const navigate = useNavigate();
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [paginateData, setPaginateData] = useState([]);

  const [dataSum, setdataSum] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/freeeze/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setdataSum(data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const handlePage = (event) => {
    try {
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .get(
          `https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/freeeze/view?page=${pageNumber}`,
          config
        )
        .then((response) => {
          setData(response?.data?.data);
          setPaginateData(response?.data);
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  };

  const [dateValue, setDateValue] = useState([]);
  const [dateValueShow, setDateValueShow] = useState([]);

  const refSubmitDis = useRef();

  const handleSubmitData = (event) => {
    event.preventDefault();

    refSubmitDis.current.setAttribute("disabled", true);
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    axios
      .get(
        `https://api.rubiconlibertygroup.xyz/api/admin/withdrawal/freeeze/filter?start_date=${dateValue[0]}&end_date=${dateValue[1]}`,
        config
      )
      .then((response) => {
        toast.success(`${response?.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setData(response.data.data);
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        refSubmitDis.current.removeAttribute("disabled");
      });
  };

  const { RangePicker } = DatePicker;

  const Time = () => {
    return (
      <>
        <RangePicker
          value={dateValueShow}
          onChange={(e) => {
            setDateValue(
              e.map((item) => {
                return dateFormat(item, "isoUtcDateTime");
              })
            );
            setDateValueShow(e);
          }}
        />
      </>
    );
  };

  if (!authUser?.withdrawals) {
    if (authUser?.withdrawals === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 className="page-title">Freeeze Withdrawals</h6>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-sm btn-outline-primary"
            >
              {" "}
              <i className="la la-undo"></i> Back
            </button>
          </div>

          <div className="row justify-content-center">
            <div class="col-xl-6 col-sm-6 mb-30">
              <div class="widget-two box--shadow2 has-link b-radius--5 bg--success">
                <div class="widget-two__content">
                  <h2 class="text-white">${dataSum.WithdrawalPendingSum}</h2>
                  <p class="text-white">Freeeze Withdrawals</p>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-sm-6 mb-30">
              <div class="widget-two box--shadow2 has-link b-radius--5 bg--6">
                <div class="widget-two__content">
                  <h2 class="text-white">{dataSum.length}</h2>
                  <p class="text-white">Totals Freeeze Withdrawals</p>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div className="show-filter mb-3 text-end">
                <button
                  type="button"
                  className="btn btn-outline--primary showFilterBtn btn-sm"
                >
                  <i className="las la-filter"></i> Filter
                </button>
              </div>
              <div className="card responsive-filter-card mb-4">
                <div className="card-body">
                  <form onSubmit={handleSubmitData}>
                    <div className="d-flex flex-wrap gap-4">
                      <div className="flex-grow-1">
                        <label>Date</label>
                        <br />
                        <Time className="datepicker-here form-control" />
                      </div>
                      <div className="flex-grow-1 align-self-end">
                        <button
                          ref={refSubmitDis}
                          className="btn btn-primary w-100 h-45"
                        >
                          <i className="fas fa-filter"></i> Filter
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card b-radius--10">
                <div class="card-body p-0">
                  <div class="table-responsive--sm table-responsive">
                    <table class="table table--light style--two mb-0">
                      <thead>
                        <tr>
                          <th>Network Type | Transaction</th>
                          <th>Initiated</th>
                          <th>Full Name</th>
                          <th>Amount</th>

                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length !== 0 ? (
                          data.map((data, index) => {
                            if (data) {
                              return (
                                <SingleItem
                                  data={data}
                                  index={index}
                                  key={data._id}
                                  paginateData={paginateData}
                                ></SingleItem>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td
                              className="text-muted text-center"
                              colspan="100%"
                            >
                              Data not found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* paginateLinks */}
                <CustomPagination
                  data={paginateData}
                  handlePage={handlePage}
                ></CustomPagination>
                {/* paginateLinks */}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};

export default FreeezeWithdrawals;
