import React, { useEffect, useRef, useState } from "react";
import {  Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const PriceUpdateModal = ({ setShow, singleDataId, show, setupdateData }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.rubiconlibertygroup.xyz/api/admin/stocks/edit/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
            setData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);


  const refSubmitDis = useRef();
 
  const handleUpdatePrice = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/stocks/price/update/${singleDataId}`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InpuData),
    })
      .then((res) => res.json())
      .then((data) => {
        event.target.reset();
        toast.success(`${data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setupdateData(data?.data);
          setShow(false);
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        // console.log(error)
      }
    );
  };

  function ModalComponent(props) {
    return (


        <Modal   {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="notices-modal-show"
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Update price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="crypto-modal-details-area">
            <p>Name: {data?.name}</p>
            <p>Symbol: {data?.symbol}</p>
            <p>Price: {data?.price}</p>
            </div>
          <form onSubmit={handleUpdatePrice}>
            <div class="modal-body">
              <div class="form-group">
                <label htmlFor="price" class="required">
                Price
                </label>
                <input
                  type="number"
                  class="form-control"
                  name="price"
                  required
                  id="price"
                />
              </div>
              <button ref={refSubmitDis}
                type="submit"
                class="btn btn-primary w-100 h-45"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>


     
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default PriceUpdateModal;
