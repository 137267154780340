import React, { useEffect, useRef, useState } from "react";
import { RotatingSquare } from "react-loader-spinner";
import { toast } from "react-toastify";

const RestrictedModal = ({ id, handleRestrictedModalClose }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [updateData, setUpdateData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/restricted/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setCheckedItems(data?.data);
        setLoading(false);
      });
  }, [updateData]);

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const refSubmitDis = useRef(false);
  const handleSubmitData = (event) => {
    event.preventDefault();
    const storeData = { ...checkedItems, user_id: id };
    refSubmitDis.current = true;
    fetch(`https://api.rubiconlibertygroup.xyz/api/admin/restricted/store`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(storeData),
    })
      .then((res) => res.json())
      .then((data) => {
        setUpdateData(data?.data);
        handleRestrictedModalClose();
        toast.error(`${data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        event.target.reset();
        refSubmitDis.current = false;
      })
      .catch((error) => (refSubmitDis.current = false));
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center py-5">
        <RotatingSquare
          visible={true}
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="rotating-square-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }
  return (
    <>
      <form onSubmit={handleSubmitData}>
        <div className="row">
          <div className="col-6">
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.deposit}
                    onChange={handleChange}
                    name="deposit"
                    id="deposit"
                  />
                  <label className="form-check-label" htmlFor="deposit">
                    Deposit
                  </label>
                </div>
              </div>
            </div>
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.withdraw}
                    onChange={handleChange}
                    name="withdraw"
                    id="withdraw"
                  />
                  <label className="form-check-label" htmlFor="withdraw">
                    Withdraw
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.trade}
                    onChange={handleChange}
                    name="trade"
                    id="trade"
                  />
                  <label className="form-check-label" htmlFor="trade">
                    Trade
                  </label>
                </div>
              </div>
            </div>
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.mining}
                    onChange={handleChange}
                    name="mining"
                    id="mining"
                  />
                  <label className="form-check-label" htmlFor="mining">
                    Mining
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.investment}
                    onChange={handleChange}
                    name="investment"
                    id="investment"
                  />
                  <label className="form-check-label" htmlFor="investment">
                    Investment
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="admin-role">
              <div className="form-group">
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input mt-2"
                    type="checkbox"
                    checked={checkedItems?.loan}
                    onChange={handleChange}
                    name="loan"
                    id="loan"
                  />
                  <label className="form-check-label" htmlFor="loan">
                    Loan
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button type="submit" className="btn btn-primary h-45 w-100" disabled={refSubmitDis?.current}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default RestrictedModal;
