import React from 'react'
import './assets/global/css/line-awesome.min.css';
import './assets/admin/css/app.css';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import HomeMain from './Components/Layouts/HomeMain';
import Home from './Components/Home/Home';
import CryptoCurrency from './Components/Pages/CryptoCurrency/CryptoCurrency';
import TradeSetting from './Components/Pages/TradeSetting/TradeSetting';
import Staff from './Components/Pages/Staff/Staff';
import TradeLogAll from './Components/Pages/TradeLog/TradeLogAll/TradeLogAll';
import WinTradeLog from './Components/Pages/TradeLog/WinTradeLog/WinTradeLog';
import LossTradeLog from './Components/Pages/TradeLog/LossTradeLog/LossTradeLog';
import DrawTradeLog from './Components/Pages/TradeLog/DrawTradeLog/DrawTradeLog';
import ActiveUsers from './Components/Pages/Users/ActiveUsers/ActiveUsers';
import BannedUsers from './Components/Pages/Users/BannedUsers/BannedUsers';
import EmailUnverifiedUsers from './Components/Pages/Users/EmailUnverifiedUsers/EmailUnverifiedUsers';
import PendingDeposits from './Components/Pages/Deposits/PendingDeposits/PendingDeposits';
import WithdrawalMethods from './Components/Pages/Withdrawal/WithdrawalMethods/WithdrawalMethods';
import CryptoCurrencyEdit from './Components/Pages/CryptoCurrency/CryptoCurrencyEdit';
import TradeSettingEdit from './Components/Pages/TradeSetting/TradeSettingEdit';
import AllDeposits from './Components/Pages/Deposits/AllDeposits/AllDeposits';
import ManualGateways from './Components/Pages/PaymentGateways/ManualGateways/ManualGateways';
import ManualGatewaysEdit from './Components/Pages/PaymentGateways/ManualGateways/ManualGatewaysEdit';
import ManualGatewayAdd from './Components/Pages/PaymentGateways/ManualGateways/ManualGatewayAdd';
import DepositsDetails from './Components/Pages/Deposits/DepositsDetails/DepositsDetails';
import RejectDeposits from './Components/Pages/Deposits/RejectDeposits/RejectDeposits';
import ApprovedDeposits from './Components/Pages/Deposits/ApprovedDeposits/ApprovedDeposits';
import SuccessfulDeposits from './Components/Pages/Deposits/SuccessfulDeposits/SuccessfulDeposits';
import WithdrawalsLog from './Components/Pages/Withdrawal/WithdrawalsLog/WithdrawalsLog';
import WithdrawalsDetails from './Components/Pages/Withdrawal/WithdrawalsDetails/WithdrawalsDetails';
import PendingWithdrawals from './Components/Pages/Withdrawal/PendingWithdrawals/PendingWithdrawals';
import ApprovedWithdrawals from './Components/Pages/Withdrawal/ApprovedWithdrawals/ApprovedWithdrawals';
import RejectedWithdrawals from './Components/Pages/Withdrawal/RejectedWithdrawals/RejectedWithdrawals';
import StaffAdd from './Components/Pages/Staff/StaffAdd';
import StaffEdit from './Components/Pages/Staff/StaffEdit';
import WithdrawalMethodsAdd from './Components/Pages/Withdrawal/WithdrawalMethods/WithdrawalMethodsAdd';
import WithdrawalMethodsEdit from './Components/Pages/Withdrawal/WithdrawalMethods/WithdrawalMethodsEdit';
import Details from './Components/Pages/Users/Details';
import AllUsers from './Components/Pages/Users/AllUsers/AllUsers';
import DepositHistory from './Components/Pages/Users/History/DepositHistory/DepositHistory';
import WithdrawalsHistory from './Components/Pages/Users/History/WithdrawalsHistory/WithdrawalsHistory';
import TradeLogHistory from './Components/Pages/Users/History/TradeLogHistory/TradeLogHistory';
import WinTradeLogHistory from './Components/Pages/Users/History/TradeLogHistory/WinTradeLogHistory';
import LossTradeLogHistory from './Components/Pages/Users/History/TradeLogHistory/LossTradeLogHistory';
import DrawTradeLogHistory from './Components/Pages/Users/History/TradeLogHistory/DrawTradeLogHistory';
import Transaction from './Components/Pages/Transaction/Transaction';
import MobileUnverified from './Components/Pages/Users/MobileUnverified/MobileUnverified';
import KYCUnverifiedUsers from './Components/Pages/Users/KYCUnverifiedUsers/KYCUnverifiedUsers';
import UsersWithBalance from './Components/Pages/Users/UsersWithBalance/UsersWithBalance';
import KYCPendingUsers from './Components/Pages/Users/KYCPendingUsers/KYCPendingUsers';
import KYCData from './Components/Pages/Users/KYCData/KYCData';
import UserLoginHistory from './Components/Pages/Users/History/UserLoginHistory/UserLoginHistory';
import TransactionLogs from './Components/Pages/TransactionLogs/TransactionLogs';
import StaffDetails from './Components/Pages/Staff/StaffDetails';
import Login from './Components/Auth/Login';
import Reset from './Components/Auth/Reset';
import AdminPrivateRoute from './Route/AdminPrivateRoute';
import LoginRoute from './Route/LoginRoute';
import ReSetPassword from './Components/Auth/ReSetPassword';
import Profile from './Components/Pages/Admin/Profile/Profile';
import Password from './Components/Pages/Admin/Password/Password';
import LiveChats from './Components/Pages/LiveChat/LiveChats/LiveChats';
import MiningView from './Components/Pages/Mining/MiningView';
import MiningAdd from './Components/Pages/Mining/MiningAdd';
import MiningEdit from './Components/Pages/Mining/MiningEdit';
import PendingLoan from './Components/Pages/Loan/PendingLoan';
import AprovedNow from './Components/Pages/Loan/AprovedNow';
import ExpriedMining from './Components/Pages/Mining/ExpriedMining/ExpriedMining';
import AllMining from './Components/Pages/Mining/AllMining/AllMining';
import UserRunningMining from './Components/Pages/UserMining/UserRunningMining';
import UserCompleteMining from './Components/Pages/UserMining/UserCompleteMining';
import UserAllMining from './Components/Pages/UserMining/UserAllMining';
import AprovedLoan from './Components/Pages/Loan/AprovedLoan';
import RejectLoan from './Components/Pages/Loan/RejectLoan';
import CompleteLoan from './Components/Pages/Loan/CompleteLoan';
import PendingLiveChat from './Components/Pages/LiveChat/PendingLiveChat/PendingLiveChat';
import LiveChatDetails from './Components/Pages/LiveChat/LiveChatDetails/LiveChatDetails';
import LiveChatsHistory from './Components/Pages/LiveChat/LiveChatsHistory/LiveChatsHistory';
import LiveChatHistoryDetails from './Components/Pages/LiveChat/LiveChatsHistory/LiveChatHistoryDetails';
import StaffDetailsByEmail from './Components/Pages/Staff/StaffDetailsByEmail';
import Forex from './Components/Pages/Forex/Forex';
import Stocks from './Components/Pages/Stocks/Stocks';
import ForexEdit from './Components/Pages/Forex/ForexEdit';
import StocksEdit from './Components/Pages/Stocks/StocksEdit';
import Indices from './Components/Pages/Indices/Indices';
import IndicesEdit from './Components/Pages/Indices/IndicesEdit';
import Energises from './Components/Pages/Energises/Energises';
import EnergisesEdit from './Components/Pages/Energises/EnergisesEdit';
import Metals from './Components/Pages/Metals/Metals';
import MetalsEdit from './Components/Pages/Metals/MetalsEdit';
import LoanPackageView from './Components/Pages/Loan/LoanPackage/LoanPackageView';
import LoanPackageEdit from './Components/Pages/Loan/LoanPackage/LoanPackageEdit';
import LoanPackageAdd from './Components/Pages/Loan/LoanPackage/LoanPackageAdd';
import InvestmentPlan from './Components/Pages/InvestmentPlan/InvestmentPlan';
import InvestmentPlanAdd from './Components/Pages/InvestmentPlan/InvestmentPlanAdd';
import InvestmentPlanEdit from './Components/Pages/InvestmentPlan/InvestmentPlanEdit';
import UserInvestmentPlan from './Components/Pages/UserInvestmentPlan/UserInvestmentPlan';
import UserInvestmentPlanAll from './Components/Pages/UserInvestmentPlan/UserInvestmentPlanAll';
import UserInvestmentPlanComplate from './Components/Pages/UserInvestmentPlan/UserInvestmentPlanComplate';
import StaffLoginInfo from './Components/Pages/Staff/StaffLoginInfo/StaffLoginInfo';
import UserPermission from './Components/Pages/Staff/UserPermission/UserPermission';
import UserPermissionView from './Components/Pages/UserPermission/UserPermission/UserPermissionView';
import UserPermissionDeposit from './Components/Pages/UserPermission/Deposit/UserPermissionDeposit/UserPermissionDeposit';
import UserPermissionDepositSuccess from './Components/Pages/UserPermission/Deposit/UserPermissionDepositSuccess/UserPermissionDepositSuccess';
import UserPermissionDepositReject from './Components/Pages/UserPermission/Deposit/UserPermissionDepositReject/UserPermissionDepositReject';
import UserPermissionDepositPending from './Components/Pages/UserPermission/Deposit/UserPermissionDepositPending/UserPermissionDepositPending';
import UserPermissionWithdrawalPending from './Components/Pages/UserPermission/Withdrawal/UserPermissionWithdrawalPending/UserPermissionWithdrawalPending';
import UserPermissionWithdrawalSuccess from './Components/Pages/UserPermission/Withdrawal/UserPermissionWithdrawalSuccess/UserPermissionWithdrawalSuccess';
import UserPermissionWithdrawalReject from './Components/Pages/UserPermission/Withdrawal/UserPermissionWithdrawalReject/UserPermissionWithdrawalReject';
import UserPermissionWithdrawal from './Components/Pages/UserPermission/Withdrawal/UserPermissionWithdrawal/UserPermissionWithdrawal';
import News from './Components/Pages/News/News/News';
import NewsAdd from './Components/Pages/News/News/NewsAdd';
import NewsUpdate from './Components/Pages/News/News/NewsUpdate';
import CategoryUpdate from './Components/Pages/News/Category/CategoryUpdate';
import CategoryView from './Components/Pages/News/Category/CategoryView';
import TradeLogDetails from './Components/Pages/TradeLog/TradeLogDetails/TradeLogDetails';
import BitcoinMiningDragAndDrop from './Components/Pages/Mining/MiningDragAndDrop/BitcoinMiningDragAndDrop/BitcoinMiningDragAndDrop';
import GoldMiningDragAndDrop from './Components/Pages/Mining/MiningDragAndDrop/GoldMiningDragAndDrop/GoldMiningDragAndDrop';
import InvestmentPlanDragAndDrop from './Components/Pages/InvestmentPlan/InvestmentPlanDragAndDrop/InvestmentPlanDragAndDrop';
import LoanDragAndDrop from './Components/Pages/Loan/LoanPackage/LoanDragAndDrop/LoanDragAndDrop';
import SocialSupport from './Components/Pages/SocialSupport/SocialSupport';
import ManualGatewaysDragAndDrop from './Components/Pages/PaymentGateways/ManualGateways/ManualGatewaysDragAndDrop/ManualGatewaysDragAndDrop';
import UserPermissionLists from './Components/Pages/Staff/UserPermission/UserPermissionLists';
import FreeezeWithdrawals from './Components/Pages/Withdrawal/FreeezeWithdrawals/FreeezeWithdrawals';
import AdminAllNotification from './Components/Pages/AdminNotification/AdminAllNotification';




function App() {

  return (
    <BrowserRouter>
      {/* <ScrollToTop />  */}
      <Routes>  
        <Route path="/admin" element={ <AdminPrivateRoute> <HomeMain /> </AdminPrivateRoute>}>
          <Route path="dashboard" element={ <Home /> } />
          <Route path="crypto/currency/list" element={<CryptoCurrency />} />
          <Route path="crypto/currency/edit/:id" element={<CryptoCurrencyEdit />} />
          <Route path="forex/list" element={<Forex />} />
          <Route path="forex/edit/:id" element={<ForexEdit />} />
          <Route path="stocks/list" element={<Stocks />} />
          <Route path="stocks/edit/:id" element={<StocksEdit />} />
          <Route path="cfd/list" element={<Indices />} />
          <Route path="cfd/edit/:id" element={<IndicesEdit />} />
          {/* <Route path="stocks/list" element={<Metals />} />
          <Route path="stocks/edit/:id" element={<MetalsEdit />} /> */}
          <Route path="commodities/list" element={<Energises />} />
          <Route path="commodities/edit/:id" element={<EnergisesEdit />} />
          <Route path="trade/setting" element={<TradeSetting />} />
          <Route path="trade/setting/edit/:id" element={<TradeSettingEdit />} />
          <Route path="staff/user/permission/:id" element={<UserPermission />} />
          <Route path="staff/user/permission/lists/:id" element={<UserPermissionLists />} />
          <Route path="staff/login/info/:id" element={<StaffLoginInfo />} />
          <Route path="staff" element={<Staff />} />
          <Route path="staff/add" element={<StaffAdd />} />
          <Route path="staff/edit/:id" element={<StaffEdit />} />
          <Route path="staff/details/:id" element={<StaffDetails />} />
          <Route path="staff/details/by/email/:email" element={<StaffDetailsByEmail />} />
          <Route path="trade/log" element={<TradeLogAll />} />
          <Route path="trade/log/pending" element={<WinTradeLog />} />
          <Route path="trade/log/close" element={<LossTradeLog />} />
          <Route path="trade/log/details/:id" element={<TradeLogDetails />} />
          <Route path="trade/log/draw" element={<DrawTradeLog />} />
          <Route path="users/all" element={<AllUsers />} />
          <Route path="users/active" element={<ActiveUsers />} />
          <Route path="users/details/:id" element={<Details />} />
          <Route path="users/banned" element={<BannedUsers />} />
          <Route path="users/email-unverified" element={<EmailUnverifiedUsers />} />
          <Route path="users/mobile-unverified" element={<MobileUnverified />} />
          <Route path="users/kyc-unverified" element={<KYCUnverifiedUsers />} />
          <Route path="users/with-balance" element={<UsersWithBalance />} />
          <Route path="users/kyc-pending" element={<KYCPendingUsers />} />
          <Route path="users/kyc-data/:id" element={<KYCData />} />
          <Route path="deposit/pending" element={<PendingDeposits />} />
          <Route path="deposit" element={<AllDeposits />} />
          <Route path="deposit/rejected" element={<RejectDeposits />} />
          <Route path="deposit/approved" element={<ApprovedDeposits />} />
          <Route path="deposit/successful" element={<SuccessfulDeposits />} />
          <Route path="deposit/details/:id" element={<DepositsDetails />} />
          <Route path="withdraw/method" element={<WithdrawalMethods />} />
          <Route path="withdraw/method/add" element={<WithdrawalMethodsAdd />} />
          <Route path="withdraw/method/edit/:id" element={<WithdrawalMethodsEdit />} />
          <Route path="withdraw/log" element={<WithdrawalsLog />} />
          <Route path="withdraw/pending" element={<PendingWithdrawals />} />
          <Route path="withdraw/approved" element={<ApprovedWithdrawals />} />
          <Route path="withdraw/rejected" element={<RejectedWithdrawals />} />
          <Route path="withdraw/freeeze" element={<FreeezeWithdrawals />} />
          <Route path="withdraw/details/:id" element={<WithdrawalsDetails />} />
          <Route path="gateway/manual" element={<ManualGateways />} />
          <Route path="gateway/manual/new" element={<ManualGatewayAdd />} />
          <Route path="gateway/manual/edit/:id" element={<ManualGatewaysEdit />} />
          <Route path="active/gateway/manual/view" element={<ManualGatewaysDragAndDrop />} />

          <Route path="deposit/history/:id" element={<DepositHistory />} />
          <Route path="withdraw/history/:id" element={<WithdrawalsHistory />} />
          <Route path="trade/log/history/:id" element={<TradeLogHistory />} />
          <Route path="trade/log/pending/history/:id" element={<WinTradeLogHistory />} />
          <Route path="trade/log/close/history/:id" element={<LossTradeLogHistory />} />
          <Route path="trade/log/draw/history/:id" element={<DrawTradeLogHistory />} />
          <Route path="report/transaction/history/:id" element={<Transaction />} />
          <Route path="report/login/history/:id" element={<UserLoginHistory />} />
          <Route path="users/transaction/logs" element={<TransactionLogs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="password" element={<Password />} />
          <Route path="running/mining/view" element={<MiningView />} />
          <Route path="expired/mining/view" element={<ExpriedMining />} />
          <Route path="all/mining/view" element={<AllMining />} />
          <Route path="active/bitcoin/mining/view" element={<BitcoinMiningDragAndDrop />} />
          <Route path="active/gold/mining/view" element={<GoldMiningDragAndDrop />} />
          <Route path="user/running/mining/view" element={<UserRunningMining />} />
          <Route path="user/complete/mining/view" element={<UserCompleteMining />} />
          <Route path="user/all/mining/view" element={<UserAllMining />} />
          <Route path="mining/add" element={<MiningAdd />} />
          <Route path="mining/edit/:id" element={<MiningEdit />} />
          <Route path="user/pending/loan" element={<PendingLoan />} />
          <Route path="user/approved/loan" element={<AprovedLoan />} />
          <Route path="user/reject/loan" element={<RejectLoan />} />
          <Route path="user/completed/loan" element={<CompleteLoan />} />
          <Route path="aproved/loan/:id" element={<AprovedNow />} />
          <Route path="pending/live/chats/view" element={<PendingLiveChat />} />
          <Route path="chats/live/details/view/:id" element={<LiveChatDetails />} />
          <Route path="live/chats/History" element={<LiveChatsHistory />} />
          <Route path="live/chats/History/:id" element={<LiveChatHistoryDetails />} />
          {/* <Route path="live/chats/History/:id" element={<LiveChatHistoryDetails />} /> */}
  

          <Route path="loan/package/view" element={<LoanPackageView/>} />
          <Route path="loan/package/add" element={<LoanPackageAdd/>} />
          <Route path="loan/package/update/:id" element={<LoanPackageEdit/>} />
          <Route path="loan/package/active" element={<LoanDragAndDrop/>} />

          <Route path="investment/plan/lists" element={<InvestmentPlan/>} />
          <Route path="investment/plan/add" element={<InvestmentPlanAdd/>} />
          <Route path="investment/plan/update/:id" element={<InvestmentPlanEdit/>} />
          <Route path="investment/plan/active" element={<InvestmentPlanDragAndDrop/>} />

          <Route path="user/running/investment/plan/view" element={<UserInvestmentPlan />} />
          <Route path="user/complete/investment/plan/view" element={<UserInvestmentPlanComplate />} />
          <Route path="user/all/investment/plan/view" element={<UserInvestmentPlanAll />} />


          <Route path="user/view" element={<UserPermissionView />} />

          <Route path="user/deposit/view" element={<UserPermissionDeposit />} />
          <Route path="user/deposit/pending/view" element={<UserPermissionDepositPending />} />
          <Route path="user/deposit/success/view" element={<UserPermissionDepositSuccess />} />
          <Route path="user/deposit/reject/view" element={<UserPermissionDepositReject />} />
          <Route path="user/withdrawal/pending/view" element={<UserPermissionWithdrawalPending />} />
          <Route path="user/withdrawal/success/view" element={<UserPermissionWithdrawalSuccess />} />
          <Route path="user/withdrawal/reject/view" element={<UserPermissionWithdrawalReject />} />
          <Route path="user/withdrawal/view" element={<UserPermissionWithdrawal />} />

          {/* news  */}

          <Route path="news/view" element={<News/>} />
          <Route path="news/add" element={<NewsAdd />} />
          <Route path="news/update/:id" element={<NewsUpdate />} />
          <Route path="news/category/view" element={<CategoryView/>} />
          <Route path="news/category/update/:id" element={<CategoryUpdate/>} />
          <Route path="social/support" element={<SocialSupport/>} />
          <Route path="all/notification" element={<AdminAllNotification/>} />
            
        </Route>

        
        <Route path="/" element={<LoginRoute><Login /></LoginRoute> } />
        <Route path="/admin/password/reset" element={<LoginRoute><Reset /></LoginRoute> } />
        <Route path="/reset/password/:id/:token" element={<LoginRoute> <ReSetPassword /> </LoginRoute>} />

        <Route path="live/chats" element={<LiveChats />} />
      </Routes>
      
    </BrowserRouter>


  );
}

export default App;